import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/Users/jxie/projects/lyra/packages/web/src/components/content/ContentPage.tsx";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Grid = makeShortcode("Grid");
const SelectBox = makeShortcode("SelectBox");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "reflecting-on-rules-and-consequences"
    }}>{`Reflecting on rules and consequences`}</h1>
    <p>{`Click on the questions that you can answer ‘YES’ to when applied to your family.`}</p>
    <Grid container spacing={3} mdxType="Grid">
  <Grid item xs={12} sm={6} md={4} mdxType="Grid">
    <SelectBox id="reflectOnRulesItem1" fullWidth={true} helpful={true} mdxType="SelectBox">
      <p>
        Do you have clear rules <b>about homework</b>?
      </p>
    </SelectBox>
  </Grid>
  <Grid item xs={12} sm={6} md={4} mdxType="Grid">
    <SelectBox id="reflectOnRulesItem2" fullWidth={true} helpful={true} mdxType="SelectBox">
      <p>
        Do you make sure you <b>praise your teenager</b> for the good behaviour they show?
      </p>
    </SelectBox>
  </Grid>
  <Grid item xs={12} sm={6} md={4} mdxType="Grid">
    <SelectBox id="reflectOnRulesItem3" fullWidth={true} helpful={true} mdxType="SelectBox">
      <p>
        Does your teenager know what chores they are expected to do and are there <b>consequences</b> when these are not
        done?
      </p>
    </SelectBox>
  </Grid>
  <Grid item xs={12} sm={6} md={4} mdxType="Grid">
    <SelectBox id="reflectOnRulesItem4" fullWidth={true} helpful={true} mdxType="SelectBox">
      <p>
        Have you ever used <b>loss of privileges</b> as a consequence?
      </p>
    </SelectBox>
  </Grid>
  <Grid item xs={12} sm={6} md={4} mdxType="Grid">
    <SelectBox id="reflectOnRulesItem5" fullWidth={true} helpful={true} mdxType="SelectBox">
      <p>
        Would <b>paying for a movie and popcorn as a reward</b> be something you might do for your teenager?
      </p>
    </SelectBox>
  </Grid>
  <Grid item xs={12} sm={6} md={4} mdxType="Grid">
    <SelectBox id="reflectOnRulesItem6" fullWidth={true} helpful={true} mdxType="SelectBox">
      <p>
        Are there rules in your house for what happens if your teenager is <b>verbally or physically abusive</b>?
      </p>
    </SelectBox>
  </Grid>
  <Grid item xs={12} sm={6} md={4} mdxType="Grid">
    <SelectBox id="reflectOnRulesItem7" fullWidth={true} helpful={true} mdxType="SelectBox">
      <p>
        Do you allow <b>extra privileges or freedom</b> for your teenager to encourage them for doing the right thing?
      </p>
    </SelectBox>
  </Grid>
  <Grid item xs={12} sm={6} md={4} mdxType="Grid">
    <SelectBox id="reflectOnRulesItem8" fullWidth={true} helpful={true} mdxType="SelectBox">
      <p>
        Have you ever <b>grounded your teenager</b> for poor behaviour?
      </p>
    </SelectBox>
  </Grid>
  <Grid item xs={12} sm={6} md={4} mdxType="Grid">
    <SelectBox id="reflectOnRulesItem9" fullWidth={true} helpful={true} mdxType="SelectBox">
      <p>
        Is your teenager clear about how much and for what purposes they can spend time <b>using the internet</b>?
      </p>
    </SelectBox>
  </Grid>
  <Grid item xs={12} sm={6} md={4} mdxType="Grid">
    <SelectBox id="reflectOnRulesItem10" fullWidth={true} helpful={true} mdxType="SelectBox">
      <p>
        Would imposing <b>extra chores be an appropriate consequence for rule-breaking</b> in your household?
      </p>
    </SelectBox>
  </Grid>
  <Grid item xs={12} sm={6} md={4} mdxType="Grid">
    <SelectBox id="reflectOnRulesItem11" fullWidth={true} helpful={true} mdxType="SelectBox">
      <p>
        Are you flexible with <b>a curfew</b> on some occasions, for example, during school holidays?
      </p>
    </SelectBox>
  </Grid>
  <Grid item xs={12} sm={6} md={4} mdxType="Grid">
    <SelectBox id="reflectOnRulesItem12" fullWidth={true} helpful={true} mdxType="SelectBox">
      <p>
        Do your ever <b>thank your teenager</b> for sticking to the rules?
      </p>
    </SelectBox>
  </Grid>
  <Grid item xs={12} sm={6} md={4} mdxType="Grid">
    <SelectBox id="reflectOnRulesItem13" fullWidth={true} helpful={true} mdxType="SelectBox">
      <p>
        Do you have rules about how <b>pocket money</b> is spent?
      </p>
    </SelectBox>
  </Grid>
  <Grid item xs={12} sm={6} md={4} mdxType="Grid">
    <SelectBox id="reflectOnRulesItem14" fullWidth={true} helpful={true} mdxType="SelectBox">
      <p>
        Would your teenager say they had <b>a ‘bedtime’</b> and that it was important they kept to it?
      </p>
    </SelectBox>
  </Grid>
  <Grid item xs={12} sm={6} md={4} mdxType="Grid">
    <SelectBox id="reflectOnRulesItem15" fullWidth={true} helpful={true} mdxType="SelectBox">
      <p>
        Have you talked about the <b>rules for alcohol use</b> both in your home and outside? Do you know if these rules
        are being followed?
      </p>
    </SelectBox>
  </Grid>
    </Grid>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      